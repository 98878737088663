<template>
    <div class="coach-detial">
        <van-sticky>
            <div class="title">
                <span>成单明细</span>
                <div class="time" >
                    <span class="date" @click="showDate=true">{{date}}</span>
                    <span class="triangle"></span>
                </div>
            </div>
        </van-sticky>

        <div class="tab-list">
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="my-integral-list">
                        <div class="item" v-for="item in list" :key="item.id">
                            <div class="pic">
                                <van-image class="money-image" fit="cover" :src="imgMoney" alt=""/>
                                <div class="date-type">
                                    <div class="type">
                                        <span>{{item.corpName}}</span>
                                    </div>  
                                    <div class="type">
                                        <span>{{item.coachName}}</span>
                                    </div>
                                    <div class="date">
                                        {{item.billDate}}
                                    </div>
                                </div>
                            </div>
                            <div class="money">
                                ￥{{item.amount}}
                            </div>
                        </div>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
                <no-data v-show="showData"/>
            </van-pull-refresh>
        </div>

        <van-popup v-model="showDate" position="bottom" round>
            <van-datetime-picker v-model="currentDate" type="date"  title="选择年月日" @confirm="getDate"
                @cancel="showDate = false"
                :min-date="minDate"
                :max-date="maxDate"
            />
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'member/bill/coachAchievement/coachAchievement-list',
            imgMoney: require('@/assets/img/money.png'),
            active: 0,
            model: {},
            date:'',
            showDate: false,
            minDate: null,
            maxDate: null,
            currentDate: '',
        }
    },
    methods:{
        init() {
            this.initList()
        },
        getDate(value) {
            this.date = new Date(value).FormatShort()
            this.$set(this.model, 'billDate', this.date)
            this.init()
            this.showDate = false
        }
    },
    mounted() {
        this.model.corpId = this.$route.query.corpId
        this.init()
        this.currentDate = new Date()
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let day = new Date().getDate()
        this.minDate = new Date(year-3, 0, 0)
        this.date = new Date().FormatShort()
        this.maxDate = new Date(year, month, day)
    }
}   
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .coach-detial{
        margin-top: 20px;
        .title{
            color: $main;
            font-size: 24px;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $backgroundColor;
            //font-weight: bold;
            .time {
                display:flex;
                align-items: center;
                position: relative;
                padding-left: 20px;
                .date{
                    background: #fff;
                    color: $main;
                    padding: 5px 20px 5px 10px;
                    border-radius: 28px;
                }
                .triangle{
                    border:5px solid transparent;
                    border-top:5px solid $main;
                    position: absolute;
                    top: 45%;
                    left: 110px;
                }
            }
            
        }
        .my-tabs{
            .van-tabs__nav{
                background-color: $backgroundColor;
                .van-tabs__line{
                    display: none;
                }
                .van-tab{
                    color: $main;
                    opacity: .3;
                }
                .van-tab--active{
                    color: $main;
                    opacity: 1!important;
                }
            }
        }
        .my-pull-refresh{
            padding: 20px 20px 0;
            .my-integral-list{
                .item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    background-color: #fff;
                    padding:10px 20px;
                    border-radius: 9px;
                    box-shadow: 0 0 9px 6px $boxShadow;
                     .money-image{
                        width: 50px;
                        height: 50px;
                    }
                    .pic{
                        display: flex;
                        align-items: center;
                    }
                    .date-type{
                        padding-left: 5px;
                        .type{
                            font-size: 14px;
                        }
                        .date{
                            color: $gray;
                        }
                    }
                    .money{
                        font-size: 14px;
                        font-weight: bold;
                        color: $main;
                    }
                    .active{
                        font-size: 14px;
                        font-weight: bold;
                        color: $main !important;
                    }
                }
            }
        }
    }

</style>